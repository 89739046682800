<template>
  <HeaderDialog
    :title="`Aplicações de ${user.name}`"
    :show="showHide"
    :loading="loading"
    @close="close"
    width="720"
    readOnly
    :scrollable="false"
  >
    <template #toolbar-actions>
      <v-btn
        v-if="!hasSelected"
        large
        elevation="0"
        :dark="loading ? false : true"
        color="accent"
        @click="$emit('syncApps')"
        :disabled="loading"
      >
        <v-icon
          v-text="'ph-arrows-clockwise'"
          left
          size="20"
          :class="`${loading ? 'icon-spinner' : ''}`"
        />
        <span>Sincronizar</span>
      </v-btn>
      <v-btn
        v-else
        title="Deletar selecionados"
        large
        elevation="0"
        :dark="loading ? false : true"
        color="accent"
        @click="removeUserApps(selected.map((item) => item.name))"
        :disabled="loading"
      >
        <v-icon v-text="'ph-trash'" left size="20" />
        <span>Desconectar</span>
      </v-btn>
    </template>
    <template v-slot:body>
      <v-data-table
        id="dialog_user_apps_table"
        v-model="selected"
        :headers="headers"
        :items="items"
        item-key="key"
        :items-per-page="-1"
        multi-sort
        :sort-by="['has_tokens', 'name']"
        :sort-desc="[true, false]"
        show-select
        :expanded.sync="expanded"
        show-expand
        single-expand
        hide-default-footer
        class="px-4"
      >
        <template #item.name="{ item }">
          <v-list-item>
            <v-list-item-icon class="mr-5" style="height: 40px">
              <Logo :product="item.product" height="40" noMargins />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                :title="item.name"
                v-text="item.name"
                class="d-inline-block"
              />
            </v-list-item-content>
          </v-list-item>
        </template>
        <template #item.has_tokens="{ item }">
          <div class="d-flex justify-end align-center">
            <v-icon
              :color="item.has_tokens ? 'accent' : 'grey'"
              class="mx-2"
              v-text="
                item.has_tokens
                  ? 'ph-fill ph-plugs-connected'
                  : 'ph-fill ph-plugs'
              "
              :title="
                item.has_tokens
                  ? 'Aplicação conectada'
                  : 'Aplicação foi desconectada do Google'
              "
            />
            <v-btn
              v-if="item.has_tokens"
              icon
              class="mx-1"
              title="Desconectar aplicação"
              @click.stop="removeUserApps([item.name])"
            >
              <v-icon color="accent">ph-link-simple-horizontal-break</v-icon>
            </v-btn>
            <v-btn
              icon
              class="mx-1"
              title="Obter data de última autorização"
              @click.stop="expandItem(item)"
            >
              <v-icon color="accent">ph-calendar-blank</v-icon>
            </v-btn>
          </div>
        </template>
        <template #item.data-table-expand="{ item, isExpanded, expand }">
          <v-icon
            v-text="isExpanded ? 'ph-caret-up' : 'ph-caret-down'"
            @click.stop="
              expand(!isExpanded);
              !isExpanded && getUserAppLastAuth(item.name);
            "
          />
        </template>
        <template #expanded-item="{ item, headers }">
          <td :colspan="headers.length">
            <v-card-text>
              <span v-if="loadings[item.name]">
                <v-row justify="center">
                  <v-col align="center">
                    <v-progress-circular
                      color="primary"
                      indeterminate
                      rounded
                    ></v-progress-circular>
                  </v-col>
                </v-row>
              </span>
              <span v-else-if="item.last_auth">
                Data de última autorização:
                {{ formatDateAndHour(item.last_auth, true) }}
                <div v-if="item.error">Erro: {{ item.error }}</div>
              </span>
            </v-card-text>
          </td>
        </template>
      </v-data-table>
    </template>
  </HeaderDialog>
</template>

<script>
import { formatDateAndHour } from "@/helpers/services/utils";
import { errorMessages } from "@/helpers/variables/snackbarMessages";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "DialogUserApps",
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    showHide: Boolean,
    userApps: {
      type: Object,
      default: () => ({}),
    },
    superLoading: Boolean,
  },
  data: () => ({
    loadings: {},
    errors: {
      TOKEN_NOT_FOUND: () =>
        "Não foram encontradas conexões com essa aplicação.",
      FAILED_TO_DELETE_TOKENS: (data) =>
        `Algumas conexões não puderam ser deletadas: ${data.join(", ")}`,
    },
    headers: [
      {
        text: "Nome",
        value: "name",
        align: "start",
      },
      {
        text: "Aplicação conectada",
        value: "has_tokens",
        align: "end",
      },
      { text: "", value: "data-table-expand" },
    ],
    selected: [],
    expanded: [],
  }),
  computed: {
    ...mapGetters(["token"]),
    loading() {
      return (
        this.superLoading ||
        Object.values(this.loadings).some((loading) => loading)
      );
    },
    items() {
      return Object.values(this.userApps || {});
    },
    hasSelected() {
      return this.selected?.length;
    },
  },
  methods: {
    ...mapMutations(["setSnackBar"]),
    formatDateAndHour,
    syncUserAppLastAuth(app_name) {
      this.loadings = {
        ...this.loadings,
        [app_name]: true,
      };
      const url = `${process.env.VUE_APP_API_BASE_URL}/users/application-last-auths`;
      const params = {
        emails: this.user.email,
        app_name,
      };
      const headers = { Authorization: this.token };
      this.$axios
        .get(url, { params, headers })
        .then((response) => {
          const data =
            this.user.email in response.data
              ? response.data[this.user.email]
              : { apps: [] };
          const userApps = { ...this.userApps };
          if (data.last_auth) {
            userApps[app_name].last_auth =
              data.last_auth && new Date(data.last_auth);
          } else {
            userApps[app_name].timeUnknown = true;
            userApps[app_name].error = "Há mais de 180 dias.";
          }
          this.$emit("userApps", userApps);
        })
        .catch((err) => {
          const { data } = err.response;
          console.error(data);
          const code = data.code;
          const userApps = { ...this.userApps };
          userApps[app_name].error =
            errorMessages[code] || errorMessages["unknown"];
          this.$emit("userApps", userApps);
        })
        .finally(() => {
          this.loadings = {
            ...this.loadings,
            [app_name]: false,
          };
        });
    },
    removeUserApps(app_names) {
      this.loadings = {
        ...this.loadings,
        ...Object.fromEntries(app_names.map((app_name) => [app_name, true])),
      };
      const userName = this.user.name;
      const url = `${process.env.VUE_APP_API_BASE_URL}/users/${this.user.key}/applications`;
      const data = { app_names };
      const headers = { Authorization: this.token };
      this.$axios
        .delete(url, { data, headers })
        .then((response) => {
          const data = response.data || {};
          const userApps = { ...this.userApps };
          for (const appName in data) {
            const app = data[appName];
            userApps[appName] = {
              ...userApps[appName],
              ...app,
              error:
                app.error &&
                app.error.code in this.errors &&
                this.errors[app.error.code](app.error.data),
            };
          }
          this.$emit("userApps", userApps);
          this.setSnackBar({
            show: true,
            message: `O usuário "${userName}" foi desconectado ${
              app_names.length > 1
                ? "das seguintes aplicações"
                : "da seguinte aplicação"
            }: ${app_names.map((appName) => `"${appName}"`).join(", ")}.`,
          });
        })
        .catch((err) => {
          const { data } = err.response;
          console.error(data);
          const code = data.code;
          const userApps = { ...this.userApps };
          for (const app_name of app_names) {
            userApps[app_name].error =
              errorMessages[code] || errorMessages["unknown"];
          }
          this.$emit("userApps", userApps);
          this.setSnackBar({
            show: true,
            color: "error",
            message: `Não foi possível desconectar o usuário "${userName}" ${
              app_names.length > 1 ? "das aplicações" : "da aplicação"
            } ${app_names.map((appName) => `"${appName}"`).join(", ")}.`,
          });
        })
        .finally(() => {
          this.loadings = {
            ...this.loadings,
            ...Object.fromEntries(
              app_names.map((app_name) => [app_name, false])
            ),
          };
        });
    },
    getUserAppLastAuth(app_name) {
      if (
        !this.userApps[app_name].last_auth &&
        !this.loadings[app_name] &&
        !this.userApps[app_name].timeUnknown
      ) {
        this.syncUserAppLastAuth(app_name);
      }
    },
    close() {
      this.$emit("close", false);
    },
    expandItem(item) {
      if (!this.expanded.includes((item) => item.key)) {
        this.expanded.push(item);
      }
      this.syncUserAppLastAuth(item.name);
    },
  },
};
</script>

<style>
#dialog_user_apps_table > .v-data-table__wrapper {
  overflow-y: hidden !important;
}
</style>
