var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showBanner)?_c('v-card',{class:`banner link py-5 ${
    _vm.dense ? 'px-6' : 'px-8 mt-4'
  } d-flex justify-space-between overflow-hidden`,attrs:{"tile":_vm.dense,"color":_vm.background,"height":_vm.bannerHeight,"flat":""},on:{"click":_vm.redirectToSelfChekout}},[(_vm.$vuetify.breakpoint.smAndUp && !_vm.isExpired && !_vm.dense)?_c('div',{staticClass:"circle rounded-circle"}):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp && _vm.isExpired)?_c('v-icon',{class:`align-self-start rotate`,attrs:{"color":"white","size":"80"},domProps:{"textContent":_vm._s('ph-fill ph-warning')}}):_vm._e(),(_vm.dense && !_vm.$vuetify.breakpoint.xs && !_vm.isExpired)?_c('Logo',{attrs:{"product":_vm.upgradeProduct,"black":_vm.hasBasic,"white":""}}):_vm._e(),_c('v-row',{class:`${
      _vm.$vuetify.breakpoint.smAndUp && !_vm.isExpired && !_vm.dense
        ? 'ml-12 pl-12'
        : 'pl-0'
    } ${_vm.$vuetify.breakpoint.smAndUp && _vm.isExpired ? 'ml-6' : 'pl-0'} ${
      _vm.dense ? 'mx-0 px-0' : ''
    }`,attrs:{"align":"center","justify":"start"}},[_c('v-col',{class:`${_vm.$vuetify.breakpoint.xs ? 'pl-0 pr-5' : ''}`,attrs:{"cols":"12","xs":"12","md":_vm.dense ? 6 : 7,"lg":_vm.dense ? 6 : 7,"xl":_vm.dense ? 7 : 8}},[(!_vm.hideTitle)?_c('v-card-title',{class:`${_vm.$vuetify.breakpoint.smAndUp ? 'text-h5' : 'mb-6'} ${
          _vm.dense ? 'text-h6 pl-0' : ''
        } py-0 font-weight-bold ${
          _vm.dense && _vm.hasBasic ? 'grey--text text--darken-4' : 'white--text'
        } break-word`},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('v-subheader',{class:`my-0 py-0 text-body-2 ${
          _vm.$vuetify.breakpoint.xs ? 'mb-6' : ''
        } ${_vm.$vuetify.breakpoint.lgAndDown ? 'my-4' : ''} ${
          _vm.dense ? 'text-body-2 pl-0 my-4' : ''
        } ${
          _vm.dense && _vm.hasBasic ? 'grey--text text--darken-4' : 'white--text'
        } break-word`},[_vm._v(" "+_vm._s(_vm.subtitle)+" ")]),(_vm.dense && _vm.hasBasic)?_c('p',{staticClass:"text-body-2"},[_vm._v(" "+_vm._s(_vm.$t("upgradeBanner.standardPlanAndUp"))+" ")]):_vm._e(),(!_vm.dense)?_c('v-btn',{staticClass:"mt-2 ml-4 text-none text-body-1 font-weight-medium overflow-hidden",attrs:{"elevation":"0","color":_vm.buttonBackground,"id":"selfCheckOutBtn","large":"","rounded":"","dark":"","block":_vm.$vuetify.breakpoint.xs || _vm.dense}},[_vm._v(" "+_vm._s(_vm.isExpired ? _vm.$t("sidebar.hirePlan") : _vm.$t("common.knowThePlans"))+" "),_c('v-icon',{staticClass:"next-icon-1",attrs:{"right":""},domProps:{"textContent":_vm._s('ph-arrow-right')}})],1):_vm._e(),(!_vm.dense && !_vm.isSmallClient)?_c('v-btn',{staticClass:"mt-2 ml-4 text-none text-body-1 font-weight-medium overflow-hidden schedule-btn",attrs:{"elevation":"0","color":_vm.buttonBackground,"id":"scheduleMeetingBtn","large":"","rounded":"","dark":"","block":_vm.$vuetify.breakpoint.xs || _vm.dense},on:{"click":function($event){$event.stopPropagation();return _vm.scheduleMeeting.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("common.scheduleMeeting"))+" "),_c('v-icon',{staticClass:"next-icon-2",attrs:{"right":""},domProps:{"textContent":_vm._s('ph-arrow-right')}})],1):_vm._e()],1),(_vm.$vuetify.breakpoint.mdAndUp || _vm.dense)?_c('v-col',{class:`${
        (_vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.md) && !_vm.dense
          ? 'ml-8'
          : ''
      } ${
        _vm.dense && _vm.$vuetify.breakpoint.mdAndUp
          ? 'd-flex justify-end align-center'
          : ''
      }`,attrs:{"cols":"12","xs":"12","md":_vm.dense ? 6 : 3,"lg":_vm.dense ? 6 : 3,"xl":_vm.dense ? 5 : 4}},[(_vm.dense)?_c('v-btn',{class:` ${
          _vm.dense ? '' : 'ml-4 mt-2'
        } text-none text-body-1 font-weight-medium overflow-hidden`,attrs:{"elevation":"0","color":_vm.buttonBackground,"large":"","rounded":"","dark":"","block":_vm.$vuetify.breakpoint.xs || _vm.dense}},[_vm._v(" "+_vm._s(_vm.isExpired ? _vm.$t("sidebar.hirePlan") : _vm.$t("common.knowThePlans"))+" "),_c('v-icon',{staticClass:"next-icon",attrs:{"right":""},domProps:{"textContent":_vm._s('ph-arrow-right')}})],1):_c('ProductPlanIcons',{staticStyle:{"z-index":"100"},attrs:{"planSlug":_vm.upgradeSlug,"width":_vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.md ? 80 : 100,"height":_vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.md ? 75 : 95,"custom-class":_vm.$vuetify.breakpoint.lgAndUp ? 'mx-4' : 'mx-2',"custom-style":"opacity: 0.9","hide-products":"","white":""}})],1):_vm._e()],1),(_vm.$vuetify.breakpoint.xl && !_vm.isExpired && !_vm.dense)?_c('div',{class:`circle-2 rounded-circle ${_vm.bigCircleBackground}`}):_vm._e(),(_vm.$vuetify.breakpoint.sm && !_vm.isExpired && !_vm.dense)?_c('div',{staticClass:"circle-3 rounded-circle"}):_vm._e(),(_vm.$vuetify.breakpoint.md && !_vm.isExpired && !_vm.dense)?_c('div',{staticClass:"circle-4 rounded-circle"}):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp && !_vm.isExpired && !_vm.dense)?_c('v-icon',{class:`align-self-end ${_vm.$vuetify.breakpoint.xs ? 'ml-6' : ''}`,attrs:{"color":"white","size":"40"},domProps:{"textContent":_vm._s('ph-bold ph-dots-nine')}}):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp && _vm.isExpired && !_vm.dense)?_c('v-icon',{class:`align-self-end ${_vm.$vuetify.breakpoint.xs ? 'ml-6' : ''}`,attrs:{"color":"white","size":"40"},domProps:{"textContent":_vm._s('ph-fill ph-smiley-x-eyes-dead')}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }